import React, { Component } from "react";

import "./image.css";

class Image extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false };

    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.handleImageErrored = this.handleImageErrored.bind(this);
  }

  handleImageLoaded() {
    this.setState({ loaded: true });
  }

  handleImageErrored() {
    this.setState({ loaded: false });
  }

  render() {
    return (
      <img
        className={this.state.loaded ? "image loaded" : "image"}
        id={this.props.id || ""}
        src={this.props.src}
        alt={this.props.alt || ""}
        onLoad={this.handleImageLoaded}
        onError={this.handleImageErrored}
      />
    );
  }
}
export default Image;

// Node Modules
import React, { Component } from "react";

// GLobal Components
import Link from "components/link/link";

// Local Components
import EmailSubscribeForm from "./components/emailSubscribeForm/emailSubscribeForm";
import LinkWithIcon from "./components/linkWithIcon/linkWithIcon";
import LinkWithArrow from "./components/linkWithArrow/linkWithArrow";

// Local CSS
import "./footer.css";
import "./footer-mobile.css";

// Local Assets
import EmailIcon from "./assets/email-icon.svg";

class Footer extends Component {
  render() {
    return (
      <div className={`footer ${this.props.fixed === true ? "fixed" : ""}`}>
        <div className="hecha">
          <Link to="/">
            <p>HECHA / 做 NYC © 2018</p>
          </Link>
        </div>
        <div className="links">
          <EmailSubscribeForm />
          <LinkWithIcon
            label="EMAIL"
            icon={EmailIcon}
            alt="Email Contact"
            to="mailto:hechanyc@gmail.com"
          />
          <LinkWithArrow label="FB" to="https://www.facebook.com/hechaNY/" />
          <LinkWithArrow label="IG" to="https://www.instagram.com/hecha_nyc" />
        </div>
      </div>
    );
  }
}
export default Footer;

// Node Modules
import React from "react";
import { withSiteData } from "react-static";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import renderHTML from "react-render-html";

// Global Components
import VideoPlayer from "components/videoPlayer/videoPlayer";

// Local Components
import ContentRotator from "./components/contentRotator/contentRotatorContainer";
import Stream from "./components/stream/stream";

// Local CSS
import "./home.css";
import "./home-mobile.css";
import "./homePopUp.css"; // remove after pop-up

export default withSiteData(({ websiteConfiguration }) => {
  const renderVideoLoop = () => (
    <video
      src={websiteConfiguration.fields.homepageVideoLoop.fields.file.url}
      autoPlay
      loop
      muted
    />
  );

  const renderVideoPlayer = () => (
    <div className="videoPlayerContainer">
      <div className="videoPlayerContainerInner">
        <VideoPlayer
          src={websiteConfiguration.fields.homepageVideoPlayer.fields.file.url}
          poster={
            websiteConfiguration.fields.homepageVideoPlayerPoster.fields.file
              .url
          }
        />
      </div>
    </div>
  );

  const renderContentRotator = () => (
    <ContentRotator
      contents={websiteConfiguration.fields.contentRotatorContent}
    />
  );

  const renderPopUp = () => (
    <div className="popUp">
      <div className="youtubeStream">
        {/* STREAM URL REPLACEMENT */}
        <Stream streamUrl="https://youtu.be/RwgIYyfrbOQ"/>
        {/* STREAM URL REPLACEMENT */}
      </div>
      <div className="days">
        <div className="day">
          <div className="header">
            DAY 1 ~ THURS 6/20 ~ Group Exhibition Opening
          </div>
          <div className="table">
            <table>
              <tbody>
                <tr className="titles">
                  <th>Time</th>
                  <th>Event</th>
                  <th>Who</th>
                </tr>
                <tr>
                  <td>7pm - 12am</td>
                  <td>Group Exhibition Reception</td>
                  <td>
                    <p>
                      <a
                        href="https://benedict.substack.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Benedict Nguyen
                      </a>
                    </p>
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://kipdav.is"
                      >
                        Kip Davis
                      </a>
                    </p>
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="mailto:lucyluz.design@gmail.com"
                      >
                        Lucy Cruz
                      </a>
                    </p>
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="mailto:miles-shelton@squarespace.com"
                      >
                        Miles Shelton
                      </a>
                    </p>
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://paulbui.tv"
                      >
                        Paul Bui
                      </a>
                    </p>
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://ryarne.format.com"
                      >
                        Ry Arne
                      </a>
                    </p>
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.tylerkjones.com"
                      >
                        Tyler Jones
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>7pm - 11pm</td>
                  <td>Live Painting</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://luzangelicafernandez.com"
                    >
                      Luz Angelica Fernandez
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="day">
          <div className="header">DAY 2 ~ FRI 6/21 ~ Live Sets</div>
          <div className="table">
            <table>
              <tbody>
                <tr className="titles">
                  <th>Time</th>
                  <th>Who</th>
                </tr>
                <tr>
                  <td>7:30pm - 8pm</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/rogelioboomboom"
                    >
                      perrX
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>8:30pm - 9pm</td>
                  <td>
                    naano tani vs{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://baitong.systems"
                    >
                      baitong.systemes
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>9:30pm - 10pm</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://cargocollective.com/lalit_k/vveiss"
                    >
                      vveiss
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>10:30pm - End</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://soundcloud.com/speakermusic"
                    >
                      Speaker Music
                    </a>{" "}
                    (ft. Ariel Valdez &{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/_cavelight/?hl=en"
                    >
                      Catalina Cavelight
                    </a>
                    )
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="day">
          <div className="header">
            DAY 3 ~ SAT 6/22 ~ Clothing Swap/Workshop & ALKHEMY Night
          </div>
          <div className="table">
            <table>
              <tbody>
                <tr className="titles">
                  <th>Time</th>
                  <th>Event</th>
                  <th>Who</th>
                </tr>
                <tr>
                  <td>12pm - 7pm</td>
                  <td>Flash Tattoo</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://clarissa-liu.com"
                    >
                      Duri4n aka Clarissa Liu
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>12pm - 7pm</td>
                  <td>Apparel</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://junior.company"
                    >
                      Junior Company
                    </a>{" "}
                    &{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://lynn1.bandcamp.com/merch"
                    >
                      Lynn
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>12pm - 4pm</td>
                  <td>Clothing Revamping Workshop + Swap</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.theragacloset.com"
                    >
                      The Raga Closet
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>12pm - 4pm</td>
                  <td>Apparel & Accessory Customization</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://possessedfetishwear.com"
                    >
                      Possessed Fetishwear
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>7pm - 10pm</td>
                  <td>DJ sets</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/insearchofalkhemy"
                    >
                      ALKHEMY DJs
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="day">
          <div className="header">DAY 4 ~ SUN 6/23 ~ Discussions</div>
          <div className="table">
            <table>
              <tbody>
                <tr className="titles">
                  <th>Time</th>
                  <th>Event</th>
                  <th>Who</th>
                </tr>
                <tr>
                  <td>12pm - 2pm</td>
                  <td>"Appetite for Colonization" Talk</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://remedifood.com"
                    >
                      Remedi Food
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>2pm - 6pm</td>
                  <td>"On Artistic Autonomy in NYC" Panel & Narcan Training</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/discakesnyc/?hl=en"
                    >
                      DisCakes
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>6pm - 6:30pm</td>
                  <td>Performance</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://vimeo.com/lilyboshapiro"
                    >
                      lily bo shapiro
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>6:30pm - 7:30pm</td>
                  <td>DJ set</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.tylerkjones.com"
                    >
                      dime
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="day">
          <div className="header">Pop-Up Information</div>
          <div className="table">
            <table>
              <tbody>
                <tr>
                  <th>Pop-Up Dates</th>
                  <th>6/20/19 - 6/23/19</th>
                </tr>
                <tr>
                  <th>Retail & Gallery Hours</th>
                  <th>12pm - 7pm</th>
                </tr>
                <tr>
                  <th>Location:</th>
                  <th>
                    <p>Refuge Arts</p>
                    <p>80 Vernon Avenue, Brooklyn, NY</p>
                    <p>11206</p>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  const renderContent = () => {
    return renderPopUp(); // remove when done testing
    switch (websiteConfiguration.fields.homepageMode) {
      case "Pop Up":
        return renderPopUp();
      case "Video Loop":
        return renderVideoLoop();
      case "Video Player":
        return renderVideoPlayer();
      case "Content Rotator":
        return renderContentRotator();
      default:
        return renderVideoLoop();
    }
  };

  return (
    <div>
      <div className="homepage">
        <div className="brandInfo">
          {renderHTML(
            documentToHtmlString(websiteConfiguration.fields.homepageTextRich)
          )}
        </div>
        {renderContent()}
      </div>
    </div>
  );
});
